import addressComp from "../address-comp.vue";
import commonApiService from "../../common/js/common-api-service";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import AddFavourite from "../../utility/js/addFav";
import { bus } from "../../../main";
//import salesService from '../../sales-order/js/sales-order-service';
import moment from "moment";
import BackToTop from "../../common/back-to-top.vue";
import commonPoService from "./common-po-service";
import Utility from "../../../shared/utility";
export default {
  data() {
    return {
      stepElement: 1,
      //
      //Search Keys
      venSearchKey: "",
      showPartShipDetails: false,
      edit_line_num: "",
      receiveSearchKey: "",
      remitSearchKey: "",
      partReturnSearchKey: "",
      partShipSearchKey: "",
      //BreadCrumb Data
      actionList: [],
      mainPage: "",
      favouriteList: [],
      showActionsList: [],
      pageUrl: "",
      subPage: "",
      showAdd: "",
      subPageUrl: "",
      appPageName: "",
      route: "",
      cancelReason: "",
      shippingReq: false,
      returnReq: false,
      backSlashParent: true,
      backSlash: true,
      showOrderedDate: true,
      showDeliveryDate: true,
      showTempShipByDate: true,
      showTempDeliveryDate: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      vendorList: [],
      remitList: [],
      receiveList: [],
      businessList: [],
      orderTypeList: [],
      paymentList: [],
      expected_serial_number_list: [],
      LineTypeList: [],
      returnResultList: [],
      partResultList: [],
      shipWareList: [],
      shipCarrierList: [],
      returnCarrierList: [],
      warrantyList: [],
      returnWareList: [],
      shippedDataList: [],
      receivedDataList: [],
      pickedDataList: [],
      flexDisplayList: [],
      shipCosmeticList: [],
      receiveCosmeticList: [],
      purchaseOrderHistoryItems: [],
      lineFlexDisplayList: [],
      selectedLink: "",
      totalRecords: 0,
      temp_waybill_id: 1,
      panel: [0, 1, 2, 3, 4],
      //disables flags
      disableVendor: false,
      purchaseOrderHistoryDialog: false,
      disablePartShip: false,
      showVenAddress: false,
      disableEmitTo: false,
      disableReceiveAdd: false,
      disableEmitAdd: false,
      disablePurchaseForm: true,
      showEditVendor: false,
      showSerialNum: false,
      projectName: "",
      businessName: "",
      sameAsVendorCheck: false,
      showEmitDDL: false,
      showReceivedDDL: false,
      showAddressDialog: false,
      showEditRemit: false,
      disableShippingSection: false,
      showFlexDialog: false,
      showLineDelete: false,
      showUpdateLine: false,
      deleteDialog: false,
      deleteFlexItem: false,
      disableNotes: false,
      deleteLine: false,
      showLineItem: false,
      disableLine: false,
      showPartRetDetails: false,
      showexpIcon: false,
      showLineUpdate: false,
      showPartData: false,
      showExpectedSr: false,
      showPartRet: false,
      showPartShip: false,
      enableEdit: true,
      showLinkDialog: false,
      deleteReasonDialog: false,
      disableSignature: false,
      disableSatDel: false,
      showWarningDialog: false,
      disableQty: false,
      addressDialogTitle: "",
      // Purchase Order Array
      purchaseOrderJson: {
        proj_id: "",
        vendorInfo: [
          {
            first: "",
            title: "",
            last: "",
            vendno: "",
            vendor_id: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone1: "",
            phone2: "",
            cell: "",
            email: "",
            fax: "",
          },
        ],
        remitInfo: [
          {
            title: "",
            last: "",
            first: "",
            vendno: "",
            vendor_id: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone1: "",
            phone2: "",
            cell: "",
            email: "",
            fax: "",
          },
        ],
        receiveInfo: [
          {
            title: "",
            first: "",
            last: "",
            vendno: "",
            vendor_id: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone1: "",
            phone2: "",
            cell: "",
            email: "",
            fax: "",
          },
        ],
        auto_generate_order_number: false,
        order_number: "",
        order_status: "",
        order_type_id: "",
        order_type: "",
        bu_id: "",
        rma: "",
        vendor_so: "",
        ordered_date: "",
        delivery_date: "",
        pay_term_id: 14,
        fob: "",
        vendor_order_number: "",
        step_number: "",
        saturday_delivery: false,
        signature_required: false,
        outbound_carrier: "",
        inbound_carrier: "",
        order_notes: "",
        flex_field: [],
        order_line: [],
      },
      // Rules for validation
      required: [(v) => !!v || "Field is required"],
      rmaValidataion: [
        (v) => !!v || "Field is required",
        (v) =>
          /^(?:[ A-Za-z0-9\s\S]){3,25}$/.test(v) ||
          "Please enter at least one text or number, RMA should be greater than 3 characters and less than 25 characters",
      ],
      priceValid: [
        (v) => parseInt(v) >= 0 || "Field is required",
        (v) =>
          /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/.test(
            v
          ) || "Upto 2 decimal places are allowed",
      ],
      max20RuleReq: [
        (v) => !!v || "Field is required",
        (v) =>
          (v || "").length <= 20 || "Max Length must be 20 characters or less",
      ],
      max30RuleReq: [
        (v) => !!v || "Field is required",
        (v) =>
          (v || "").length <= 30 || "Max Length must be 30 characters or less",
        (v) =>
          (v || "").length >= 5 || "Min Length must be 5 characters or More",
      ],
      line_required: [(v) => parseInt(v) >= 0 || "Field is required"],
      qtyRule: [
        (v) => parseInt(v) > 0 || "Field should be greater than 0",
        (v) =>
          parseInt(v) < 2147483647 || "Field should be less than 2,147,483,647",
      ],
      deleteReasonRule: [
        (v) => !!v || "Field is required",
        (v) =>
          /^(?=.*\S).+$/.test(v) || "Please enter at least one text or number",
        (v) =>
          (v || "").length <= 500 ||
          "Max Length must be 500 characters or less",
      ],
      //Table Headers
      flexHeader: [
        {
          text: "Flex Attribute",
          align: "start",
          value: "flex_code",
          class: "primary customwhite--text",
        },
        {
          text: "Flex Value",
          value: "flex_data",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      lineItemHeader: [
        {
          text: "Line#",
          align: "start",
          value: "sequence_number",
          class: "primary customwhite--text",
        },
        {
          text: "Line Type",
          value: "order_line_text",
          class: "primary customwhite--text",
        },
        {
          text: "Part Number To Ship",
          value: "sh_part_number",
          class: "primary customwhite--text",
        },
        {
          text: "Receive Part Number",
          value: "receive_part_number",
          class: "primary customwhite--text",
        },
        {
          text: "Outbound Warehouse",
          value: "sh_from_warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Inbound Warehouse",
          value: "receive_in_warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Ordered Qty",
          value: "quantity",
          class: "primary customwhite--text",
        },
        { text: "Received", value: "Rcvd", class: "primary customwhite--text" },
        { text: "Picked", value: "Picked", class: "primary customwhite--text" },
        {
          text: "Returned",
          value: "Shipped",
          class: "primary customwhite--text",
        },
        {
          text: "Open Returns",
          value: "Open_Shipments",
          class: "primary customwhite--text",
        },
        {
          text: "Open Receipts",
          value: "Open_Receipts",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
        },
      ],
      headersWarranty: [
        {
          text: "Serial No",
          align: "start",
          value: "serial_number",
          class: "primary customwhite--text",
        },
        {
          text: "Problem Description",
          value: "problem_description",
          class: "primary customwhite--text",
        },
        {
          text: "Tracking",
          value: "return_tracking_number",
          class: "primary customwhite--text",
        },
        {
          text: "Warranty",
          value: "warranty_status",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      headersPicked: [
        {
          text: "PO Line#",
          align: "start",
          value: "PO Line#",
          class: "primary customwhite--text",
        },
        {
          text: "Part No",
          value: "PartNo",
          class: "primary customwhite--text",
        },
        {
          text: "BCN",
          value: "BCN",
          class: "primary customwhite--text",
        },
        {
          text: "Serial#",
          value: "Serial#",
          class: "primary customwhite--text",
        },
        {
          text: "ESN",
          value: "ESN",
          class: "primary customwhite--text",
        },
        {
          text: "Picked",
          value: "Picked",
          class: "primary customwhite--text",
        },
        {
          text: "From Bin (LPN)",
          value: "From Bin (LPN)",
          class: "primary customwhite--text",
        },
        {
          text: "To LPN",
          value: "To LPN",
          class: "primary customwhite--text",
        },
        {
          text: "Qty",
          value: "Qty",
          class: "primary customwhite--text",
        },
      ],
      headersReceived: [
        {
          text: "PO Line#",
          align: "start",
          value: "PO Line#",
          class: "primary customwhite--text",
        },
        {
          text: "Receiver#",
          value: "Receiver#",
          class: "primary customwhite--text",
        },
        {
          text: "Part No",
          value: "Part No",
          class: "primary customwhite--text",
        },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        {
          text: "Serial",
          value: "Serial#",
          class: "primary customwhite--text",
        },
        { text: "ESN", value: "ESN", class: "primary customwhite--text" },
        { text: "Date", value: "Date", class: "primary customwhite--text" },
        { text: "Tech", value: "Tech", class: "primary customwhite--text" },
        {
          text: "Packing Slip",
          value: "Packing Slip",
          class: "primary customwhite--text",
        },
        {
          text: "Waybill#",
          value: "Waybill#",
          class: "primary customwhite--text",
        },
        { text: "Qty", value: "Qty", class: "primary customwhite--text" },
        { text: "Cost", value: "Cost", class: "primary customwhite--text" },
        {
          text: "Ext Cost",
          value: "Ext Cost",
          class: "primary customwhite--text",
        },
      ],
      headersShipped: [
        {
          text: "PO Line#",
          align: "start",
          value: "PO Line#",
          class: "primary customwhite--text",
        },
        {
          text: "Shipper#",
          value: "Shipper#",
          class: "primary customwhite--text",
        },
        {
          text: "Part No",
          value: "Part No",
          class: "primary customwhite--text",
        },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        {
          text: "Serial",
          value: "Serial#",
          class: "primary customwhite--text",
        },
        { text: "ESN", value: "ESN", class: "primary customwhite--text" },
        {
          text: "Shipped",
          value: "Shipped",
          class: "primary customwhite--text",
        },
        { text: "Tech", value: "Tech", class: "primary customwhite--text" },
        {
          text: "Packing Slip",
          value: "Packing Slip",
          class: "primary customwhite--text",
        },
        {
          text: "Waybill#",
          value: "Waybill#",
          class: "primary customwhite--text",
        },
        { text: "Qty", value: "Qty", class: "primary customwhite--text" },
        { text: "Cost", value: "Cost", class: "primary customwhite--text" },
        {
          text: "Ext Cost",
          value: "Ext Cost",
          class: "primary customwhite--text",
        },
      ],
      headersOrderHistory: [
        {
          text: "Action",
          align: "start",
          value: "Action",
          class: "primary customwhite--text",
        },
        { text: "Logged", value: "Logged", class: "primary customwhite--text" },
        { text: "User", value: "User", class: "primary customwhite--text" },
      ],
      expected_serial_number_obj: {
        serial_number: "",
        warranty_status: "N",
        asset_tag: "",
        return_tracking_number: "",
        problem_description: "",
      },
      updatedSerialObj: {
        serial_number: "",
        warranty_status: "N",
        asset_tag: "",
        return_tracking_number: "",
        problem_description: "",
      },
      temp_order_line: {
        po_line_ref: "",
        sequence_number: "",
        order_line_type: "",
        line_number: "",
        sh_part_id: "",
        sh_part_number: "",
        sh_part_description: "",
        sh_from_warehouse_id: "",
        sh_from_warehouse: "",
        sh_from_inventory_bucket: "",
        sh_quantity: "",
        sh_ship_by_date: "",
        sh_grade: "10198",
        receive_part_id: "",
        receive_part_number: "",
        receive_part_description: "",
        receive_in_warehouse_id: "",
        receive_in_warehouse: "",
        receive_in_inventory_bucket: "",
        receive_quantity: "",
        receive_unit_cost: "0.00",
        receive_rma: "",
        receive_grade: "10198",
        receive_delivery_date: "",
        receive_ext_cost: "",
        expected_serial_number: [],
        line_comment: "",
        vendor_part_number: "",
        flex_field: [],
        // Add by Dev team for showing text
        order_line_text: "",
        showPartRet: false,
        showPartShip: false,
        showBuild: "",
        showSerial: "",
      },
      flex_field_Att: "",
      flex_field_value: "",
      sequence_number: 0,
      temp_po_id: 0,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "").split("/")[0];
    //to Load Data
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.actionList = data?.actionList;
    this.mainPage = data?.mainPage;
    this.favouriteList = data?.favouriteList;
    this.pageUrl = data?.pageUrl;
    this.subPage = data?.subPage;
    this.showAdd = data?.showAdd;
    this.subPageUrl = data?.subPageUrl;
    this.appPageName = data?.appPageName;
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    // to load the Project Dropdown
    let projectData = await commonApiService.getProject("get", this.userId);
    this.projectList = projectData?.ProjectInfo;
    this.purchaseOrderId = atob(this.$route.params.purchaseOrderId);
    this.purchaseOrderDetails = await commonPoService.getPurchaseOrderDetails(
      "get",
      this.userId,
      this.purchaseOrderId
    );
    // Assigning the values we get from API to the Form JSON we created
    this.purchaseOrderJson = JSON.parse(
      JSON.stringify(this.purchaseOrderDetails.Result[0])
    );

    this.totalRecords = this.purchaseOrderJson.order_list.length;
    this.hold = this.purchaseOrderJson.is_hold;
    this.showHoldBtn = Boolean(this.purchaseOrderJson.enable_hold);
    let businessObj = {
      UserId: this.userId,
      bu_key: this.purchaseOrderJson.bu_id,
      pass: 1, // Do not Delete
    };
    this.getOrderTypeData(businessObj);
    let carrierObj = {
      UserId: this.userId,
      bu_key: this.purchaseOrderJson.bu_id,
      vendso: this.purchaseOrderJson.vendno,
      order_type: this.purchaseOrderJson.order_type_id,
      sbu_key: 0,
    };
    this.getCarrierData(carrierObj);
    let object = {
      ProjKey: this.purchaseOrderJson.proj_id,
      UserId: this.userId,
      POId: this.purchaseOrderId,
      line_id: 0,
    };

    await this.getLineTypeData(object);
    this.venSearchKey = this.purchaseOrderJson.vendorInfo[0].company;
    this.remitSearchKey = this.purchaseOrderJson.remitInfo[0].company;
    this.returnSearchKey = this.purchaseOrderJson.receiveInfo[0].company;
    this.hold = this.purchaseOrderJson.is_hold;
    this.showHoldBtn = Boolean(this.purchaseOrderJson.enable_hold);
    this.bookmark = Boolean(this.purchaseOrderJson.is_bookmark);
    this.purchaseOrderJson.cdate = this.purchaseOrderJson?.cdate
      ? Utility.convertESTToLocal(this.purchaseOrderJson.cdate)
      : Utility.convertESTToLocal(new Date());
    this.purchaseOrderJson.udate = this.purchaseOrderJson?.udate
      ? Utility.convertESTToLocal(this.purchaseOrderJson.udate)
      : Utility.convertESTToLocal(new Date());
    this.purchaseOrderJson.ordered_date = this.purchaseOrderJson.ordered_date ?
      Utility.convertESTToLocalDate(this.purchaseOrderJson.ordered_date) : "";
    if (this.purchaseOrderJson.delivery_date == "1900-01-01T00:00:00" || !this.purchaseOrderJson.delivery_date)
      this.purchaseOrderJson.delivery_date = ""
    else {
      this.purchaseOrderJson.delivery_date =
        Utility.convertESTToLocalDate(this.purchaseOrderJson.delivery_date)
    }
    LoaderDialog.visible = false;
    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    this.flexDisplayList = JSON.parse(
      JSON.stringify(this.purchaseOrderJson.flex_field)
    );
    let warrantyData = await commonPoService.getWarrentyData(
      "get",
      this.userId
    );
    if (warrantyData?.WarrantyData) {
      this.warrantyList = warrantyData.WarrantyData.map((x) => ({
        code: x.code,
        description: x.description,
      }));
    }
    this.showActionsList.push({
      Id: "PO-History",
      Icon: "mdi mdi-account-clock-outline",
      FriendlyName: "PO History",
    });
    if (this.bookmark)
      this.showActionsList.push({
        Id: "PO-BookMark-Remove",
        Icon: "mdi mdi-bookmark-outline",
        FriendlyName: "Remove Bookmark",
      });
    else
      this.showActionsList.push({
        Id: "PO-BookMark",
        Icon: "mdi mdi-bookmark",
        FriendlyName: "Add Bookmark",
      });
    if (this.showHoldBtn) {
      if (this.hold)
        this.showActionsList.push({
          Id: "PO-Release",
          Icon: "mdi mdi-play-outline",
          FriendlyName: "PO Release",
        });
      else
        this.showActionsList.push({
          Id: "PO-Hold",
          Icon: "mdi mdi-pause-box-outline",
          FriendlyName: "PO Hold",
        });
    }
  },

  // For Reload Functionality
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  // To Check if router is changed
  beforeRouteLeave(to, from, next) {
    let soBackup = JSON.parse(
      JSON.stringify(this.purchaseOrderDetails.Result[0])
    );
    soBackup.order_list.forEach((v) => {
      v.isEdited = false;
    });
    soBackup.so_id = String(soBackup.so_id);
    if (JSON.stringify(soBackup) != JSON.stringify(this.purchaseOrderJson)) {
      if (!this.final_submit) this.isEditing = true;
      else this.isEditing = false;
    } else {
      this.isEditing = false;
    }
    if (this.isEditing) {
      if (window.confirm("Leave without saving?")) {
        next();
      } else {
        return false;
      }
    }
    next();
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.purchaseOrderJson.ordered_date
        ? moment(String(this.purchaseOrderJson.ordered_date)).format(
          "MM/DD/YYYY"
        )
        : moment(String(new Date())).format("MM/DD/YYYY");
    },
    shipcomputeDateFormattedMomentjs() {
      return this.purchaseOrderJson.delivery_date
        ? moment(String(this.purchaseOrderJson.delivery_date)).format(
          "MM/DD/YYYY"
        )
        : moment(String(new Date())).format("MM/DD/YYYY");
    },
    tempDelDatecomputeDateFormattedMomentjs() {
      return this.temp_order_line.receive_delivery_date
        ? moment(String(this.temp_order_line.receive_delivery_date)).format(
          "MM/DD/YYYY"
        )
        : moment(String(this.purchaseOrderJson.delivery_date)).format(
          "MM/DD/YYYY"
        );
    },
    shipByDateDatecomputeDateFormattedMomentjs() {
      return this.temp_order_line.sh_ship_by_date
        ? moment(String(this.temp_order_line.sh_ship_by_date)).format(
          "MM/DD/YYYY"
        )
        : moment(String(new Date())).format("MM/DD/YYYY");
    },
  },
  mounted() {
    this.setActionFunction();
  },
  methods: {
    //to check if its a number of not
    isNumber(event, loc) {
      if (loc == "number") {
        let value = Utility.isNumber(event)
        return value
      }
      else {
        let value = Utility.isNumberWithPeriod(event)
        return value
      }
    },
    // Add Serail Num
    onClickSaveWaybill(obj) {
      if (this.$refs.ExpectedSrForm.validate()) {
        if (this.temp_order_line.expected_serial_number.length <= 0) {
          let expected_ord_seq =
            this.temp_order_line.expected_serial_number.length == undefined ||
              this.temp_order_line.expected_serial_number.length == null
              ? 0
              : this.temp_order_line.expected_serial_number.length;
          this.temp_waybill_id = this.temp_waybill_id + 1;
          obj["temp_waybill_id"] = this.temp_waybill_id;
          obj["sequence_number"] = expected_ord_seq + 1;
          obj["is_Added"] = true;
          obj["is_Deleted"] = false;
          obj["pobcnId"] = 0;
          this.temp_order_line?.expected_serial_number?.push(obj);
          this.expected_serial_number_obj = {
            serial_number: "",
            warranty_status: "N",
            asset_tag: "",
            return_tracking_number: "",
            problem_description: "",
          };
          this.$refs.ExpectedSrForm.resetValidation();
        } else {
          let validSerial = {};
          if (!obj.serialNum) {
            validSerial = this.temp_order_line.expected_serial_number.find(
              (x) => x.return_tracking_number == obj.trackNum
            );
          } else {
            validSerial = this.temp_order_line.expected_serial_number.find(
              (x) => x.serial_number == obj.serialNum
            );
          }
          if (!obj.trackNum && !obj.validSerial)
            validSerial = this.temp_order_line.expected_serial_number.find(
              (x) => x.serial_number == obj.serialNum
            );
          else {
            validSerial = this.temp_order_line.expected_serial_number.find(
              (x) =>
                x.return_tracking_number == obj.trackNum ||
                x.serial_number == obj.serialNum
            );
          }
          if (!validSerial) {
            let expected_ord_seq =
              this.temp_order_line.expected_serial_number.length == undefined ||
                this.temp_order_line.expected_serial_number.length == null
                ? 0
                : this.temp_order_line.expected_serial_number.length;
            this.temp_waybill_id = this.temp_waybill_id + 1;
            (obj["temp_waybill_id"] = this.temp_waybill_id),
              (obj["sequence_number"] = expected_ord_seq + 1);
            this.temp_order_line.expected_serial_number.push(obj);
            this.expected_serial_number_list.push(obj);
            this.expected_serial_number_obj = {
              serial_number: "",
              warranty_status: "N",
              asset_tag: "",
              return_tracking_number: "",
              problem_description: "",
            };
            this.$refs.ExpectedSrForm.resetValidation();
          } else {
            if (validSerial?.serial_number) {
              let Alert = {
                type: "error",
                isShow: true,
                message:
                  "The Serial number " +
                  this.expected_serial_number_obj.serial_number +
                  " is already added in the order line",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              validSerial = "";
            } else {
              let Alert = {
                type: "error",
                isShow: true,
                message:
                  "The Tracking number " +
                  this.expected_serial_number_obj.trackNum +
                  " is already added in the order line",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              validSerial = "";
            }
          }
        }
      }
    },
    // Edit Searil Num
    editSerialNum(item) {
      this.updatedSerialObj = JSON.parse(JSON.stringify(item));
      this.showSerialNum = true;
      this.temp_waybill_id = item.temp_waybill_id;
    },
    // update Waybill
    onClickUpdateWaybill(updatedSerialObj) {
      this.temp_order_line.expected_serial_number =
        this.temp_order_line.expected_serial_number.map((p) =>
          p.temp_waybill_id == this.temp_waybill_id
            ? {
              ...p,
              serial_number: updatedSerialObj.serial_number,
              asset_tag: updatedSerialObj.asset_tag,
              problem_description: updatedSerialObj.problem_description,
              warranty_status: updatedSerialObj.warranty_status,
              return_tracking_number: updatedSerialObj.return_tracking_number,
            }
            : p
        );
      this.expected_serial_number_list = this.expected_serial_number_list.map(
        (p) =>
          p.temp_waybill_id == this.temp_waybill_id
            ? {
              ...p,
              serial_number: updatedSerialObj.serial_number,
              asset_tag: updatedSerialObj.asset_tag,
              problem_description: updatedSerialObj.problem_description,
              warranty_status: updatedSerialObj.warranty_status,
              return_tracking_number: updatedSerialObj.return_tracking_number,
            }
            : p
      );
      this.showSerialNum = false;
    },
    onClickDeleteSerial() {
      if (this.temp_order_line.expected_serial_number.is_Added) {
        this.temp_order_line.expected_serial_number =
          this.temp_order_line?.expected_serial_number.filter(
            (x) => x.temp_waybill_id !== this.temp_waybill_id
          );
      } else {
        this.expected_serial_number_list =
          this.expected_serial_number_list.filter(
            (x) => x.temp_waybill_id !== this.temp_waybill_id
          );
        this.temp_order_line.expected_serial_number.find(
          (v) => v.temp_waybill_id === this.temp_waybill_id
        ).is_Deleted = true;
      }
      this.showSerialNum = false;
    },

    //  View lIne item
    async lineItemsView(item) {
      this.disableLine = true;
      let lineItemData = {};
      this.showLineItem = true;
      this.showLineUpdate = true;
      this.showPartData = true;
      if (item.order_line_type == 1) {
        this.panel = [0];
      }
      else if (item.order_line_type == 8) {
        this.panel = [1];
      }
      else {
        this.panel = [0, 1];
      }


      this.shipWareList = [];
      this.edit_line_num = item.line_number;
      this.returnWareList = [];

      this.shipPartKey = item.sh_part_id;
      if (item.is_Added || item.isEdited) {
        this.temp_order_line = JSON.parse(
          JSON.stringify(
            this.purchaseOrderJson?.order_line.filter(
              (x) => x.line_number == item.line_number
            )[0]
          )
        );
        this.showPartRet = this.temp_order_line.showPartRet;
        this.showPartShip = this.temp_order_line.showPartShip;
      } else {
        lineItemData = await commonPoService.getLineItemData(
          "get",
          this.userId,
          item.line_id
        );
        this.temp_order_line = JSON.parse(
          JSON.stringify(lineItemData.LineItem[0])
        );
        this.showPartRet = lineItemData.LineItem[0].config_det[0].enable_left
          ? lineItemData.LineItem[0].config_det[0].enable_left
          : this.temp_order_line.showPartRet;
        this.showPartShip = lineItemData.LineItem[0].config_det[0].enable_right
          ? lineItemData.LineItem[0].config_det[0].enable_right
          : this.temp_order_line.showPartShip;
      }
      if (this.temp_order_line.receive_delivery_date == "1900-01-01T00:00:00" || !this.temp_order_line.receive_delivery_date)
        this.temp_order_line.receive_delivery_date = ""
      else {
        this.temp_order_line.receive_delivery_date =
          Utility.convertESTToLocalDate(this.temp_order_line.receive_delivery_date)
      }
      if (this.temp_order_line.receive_delivery_date == "1900-01-01T00:00:00" || !this.temp_order_line.receive_delivery_date)
        this.temp_order_line.receive_delivery_date = ""
      else {
        this.temp_order_line.receive_delivery_date =
          Utility.convertESTToLocalDate(this.temp_order_line.receive_delivery_date);
      }
      if (this.temp_order_line.sh_ship_by_date == "1900-01-01T00:00:00" || !this.temp_order_line.sh_ship_by_date)
        this.temp_order_line.sh_ship_by_date = ""
      else {
        this.temp_order_line.sh_ship_by_date =
          Utility.convertESTToLocalDate(this.temp_order_line.sh_ship_by_date);
      }
      this.expected_serial_number_list = JSON.parse(
        JSON.stringify(this.temp_order_line.expected_serial_number)
      ).filter((x) => x.is_Deleted == false);
      this.lineFlexDisplayList = JSON.parse(
        JSON.stringify(this.temp_order_line.flex_field)
      ).filter((x) => x.is_Deleted == false);
      this.showPartDetails = true;
      this.temp_order_line.receive_in_warehouse_id =
        this.temp_order_line.receive_in_warehouse_id +
        "_" +
        this.temp_order_line.receive_in_inventory_bucket;
      this.temp_order_line.receive_ext_cost =
        this.temp_order_line.receive_unit_cost *
        this.temp_order_line.receive_quantity;
      this.partShipSearchKey = this.temp_order_line.sh_part_number;
      this.temp_order_line.sh_from_warehouse_id =
        this.temp_order_line.sh_from_warehouse_id +
        "_" +
        this.temp_order_line.sh_from_inventory_bucket;
      this.showLineItem = true;
      this.showLineDelete = true;
      let object = {
        ProjKey: this.purchaseOrderJson.proj_id,
        UserId: this.userId,
        POId: this.purchaseOrderId,
        line_id: 0,
      };

      await this.getLineTypeData(object);
      this.partReturnSearchKey = this.temp_order_line.receive_part_number;
      let warehouseObj = {
        part_num: this.temp_order_line.receive_part_number
          ? this.temp_order_line.receive_part_number
          : this.temp_order_line.sh_part_number,
        potype_key: this.temp_order_line.order_line_type,
        order_bu_key: this.purchaseOrderJson.bu_id,
        order_type: this.purchaseOrderJson.order_type_id,
        po_key: this.purchaseOrderId,
        UserId: this.userId,
        loca_key: 0,
        line_id: this.temp_order_line?.line_Id,
      };
      this.getWarehouseData(warehouseObj, "viewLine");
      let listObj = this.LineTypeList?.filter(
        (x) => x.so_type_id == item.order_line_type
      );
      this.disableQty = listObj[0]?.to_disable_quantity == 1 ? true : false;
      this.disablePartShip =
        listObj[0]?.to_disable_ReturnSel == 1 ? true : false;
    },
    // Edit Line ITem
    editLineItem() {
      this.temp_order_line.showPartShip = this.showPartShip;
      this.temp_order_line.showPartRet = this.showPartRet;
      let temp_objIdx = this.purchaseOrderJson.order_list.findIndex(
        (v) => v.line_number === this.temp_order_line.line_number
      );
      if (!~temp_objIdx) {
        return;
      }
      let temp_obj = this.purchaseOrderJson.order_list[temp_objIdx];
      if (this.temp_order_line.is_Added) {
        this.purchaseOrderJson.order_line =
          this.purchaseOrderJson?.order_line.filter(
            (x) => x.line_number !== this.edit_line_num
          );
        this.temp_order_line.is_Edited = false;
        this.temp_order_line.is_Deleted = false;
        this.temp_order_line.receive_in_warehouse_id =
          this.temp_order_line?.receive_in_warehouse_id?.split("_")[0];
        this.temp_order_line.sh_from_warehouse_id =
          this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
        this.purchaseOrderJson.order_line.push(this.temp_order_line);
      } else {
        this.temp_order_line.is_Deleted = false;
        this.temp_order_line.is_Added = false;
        this.temp_order_line.is_Edited = true;
        this.purchaseOrderJson.order_line =
          this.purchaseOrderJson?.order_line.filter(
            (x) => x.line_number !== this.edit_line_num
          );
        this.purchaseOrderJson.order_line.push(this.temp_order_line);
      }
      if (this.showPartShip && this.showPartRet) {

        if (
          this.$refs.PartShipForm.validate() &&
          this.$refs.PartRecieveForm.validate()
        ) {
          temp_obj.receive_part_number = this.temp_order_line.receive_part_number
          temp_obj.receive_part_description = this.temp_order_line.receive_part_description
          temp_obj.sh_part_number = this.temp_order_line.sh_part_number
          temp_obj.sh_part_description = this.temp_order_line.sh_part_description
          this.temp_order_line.receive_in_warehouse_id =
            this.temp_order_line?.receive_in_warehouse_id.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id =
            this.temp_order_line?.sh_from_warehouse_id.split("_")[0];
          temp_obj.quantity =
            this.temp_order_line.sh_quantity != ""
              ? parseInt(this.temp_order_line.sh_quantity)
              : parseInt(this.temp_order_line.receive_quantity);
          temp_obj.Open_Shipments = temp_obj.quantity - temp_obj.Shipped;
          temp_obj.Open_Receipts = temp_obj.quantity - temp_obj.Rcvd;
          temp_obj.isEdited = true;
          this.temp_order_line.receive_in_warehouse_id =
            this.temp_order_line?.receive_in_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id =
            this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          this.showLineItem = false;
          temp_obj.receive_in_warehouse = this.temp_order_line.receive_in_warehouse
          temp_obj.receive_in_inventory_bucket = this.temp_order_line.receive_in_inventory_bucket
          temp_obj.sh_from_warehouse = this.temp_order_line.sh_from_warehouse
          temp_obj.sh_from_inventory_bucket = this.temp_order_line.sh_from_inventory_bucket

          this.purchaseOrderJson.order_list.splice(temp_objIdx, 1, temp_obj);
        }
      } else if (this.showPartShip && !this.showPartRet) {
        if (this.$refs.PartShipForm.validate()) {
          temp_obj.receive_part_number = this.temp_order_line.receive_part_number
          temp_obj.receive_part_description = this.temp_order_line.receive_part_description
          temp_obj.sh_part_number = this.temp_order_line.sh_part_number
          temp_obj.sh_part_description = this.temp_order_line.sh_part_description
          temp_obj.quantity =
            this.temp_order_line.sh_quantity != ""
              ? parseInt(this.temp_order_line.sh_quantity)
              : parseInt(this.temp_order_line.receive_quantity);
          temp_obj.Open_Shipments = temp_obj.quantity - temp_obj.Shipped;
          temp_obj.receive_part_number = "";
          temp_obj.receive_part_description = "";
          temp_obj.Open_Receipts = 0;
          temp_obj.isEdited = true;
          this.temp_order_line.receive_in_warehouse_id =
            this.temp_order_line?.receive_in_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id =
            this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          this.showLineItem = false;
          temp_obj.receive_in_warehouse = this.temp_order_line.receive_in_warehouse
          temp_obj.receive_in_inventory_bucket = this.temp_order_line.receive_in_inventory_bucket
          temp_obj.sh_from_warehouse = this.temp_order_line.sh_from_warehouse
          temp_obj.sh_from_inventory_bucket = this.temp_order_line.sh_from_inventory_bucket
          this.purchaseOrderJson.order_list.splice(temp_objIdx, 1, temp_obj);
        }
      } else if (!this.showPartShip && this.showPartRet) {
        if (this.$refs.PartRecieveForm.validate()) {
          temp_obj.receive_part_number = this.temp_order_line.receive_part_number
          temp_obj.receive_part_description = this.temp_order_line.receive_part_description
          temp_obj.sh_part_number = this.temp_order_line.sh_part_number
          temp_obj.sh_part_description = this.temp_order_line.sh_part_description
          temp_obj.quantity =
            this.temp_order_line.sh_quantity != ""
              ? parseInt(this.temp_order_line.sh_quantity)
              : parseInt(this.temp_order_line.receive_quantity);
          temp_obj.Open_Receipts = temp_obj.quantity - temp_obj.Rcvd;
          temp_obj.sh_part_number = "";
          temp_obj.sh_part_description = "";
          temp_obj.Open_Shipments = 0;
          temp_obj.isEdited = true;
          temp_obj.quantity =
            this.temp_order_line.sh_quantity != ""
              ? parseInt(this.temp_order_line.sh_quantity)
              : parseInt(this.temp_order_line.receive_quantity);
          this.temp_order_line.receive_in_warehouse_id =
            this.temp_order_line?.receive_in_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id =
            this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          this.showLineItem = false;
          temp_obj.receive_in_warehouse = this.temp_order_line.receive_in_warehouse
          temp_obj.receive_in_inventory_bucket = this.temp_order_line.receive_in_inventory_bucket
          temp_obj.sh_from_warehouse = this.temp_order_line.sh_from_warehouse
          temp_obj.sh_from_inventory_bucket = this.temp_order_line.sh_from_inventory_bucket
          this.purchaseOrderJson.order_list.splice(temp_objIdx, 1, temp_obj);
        }
      }
    },
    async addAddress(vendorObj, val) {
      if (val == "vendor") {
        {
          let obj = {
            CuJson: JSON.stringify(vendorObj),
            pass: 1, //Do not remove
          };

          let res = await commonPoService.postAddVendor("post", obj, true);
          this.purchaseOrderJson.vendorInfo[0] = JSON.parse(
            JSON.stringify(vendorObj)
          );
          this.purchaseOrderJson.vendorInfo[0].vendor_id =
            res?.Result[0]?.Vend_Id; // DO not Remove
          this.venSearchKey = this.purchaseOrderJson.vendorInfo[0].company;
        }
        this.showAddressDialog = false;
        this.showVenAddress = true;
      }
      // new ship is added
      this.purchaseOrderJson.remitInfo[0] = JSON.parse(
        JSON.stringify(vendorObj)
      );
      this.remitSearchKey = this.purchaseOrderJson.remitInfo[0].company;
      this.showAddressDialog = false;
      this.showRemitAddress = true;
    },
    commonDeleteFunction(item) {
      if (item == "deleteFlex") {
        if (this.showLineItem) {
          this.temp_order_line.flex_field =
            this.temp_order_line.flex_field.filter(
              (x) => x.sequence_number !== this.sequence_number
            );
          this.temp_order_line.flex_field.find(
            (v) => v.sequence_number === this.sequence_number
          ).is_Deleted = true;
          if (
            this.temp_order_line.flex_field.find(
              (v) => v.sequence_number === this.sequence_number
            ).is_Deleted &&
            this.temp_order_line.flex_field.find(
              (v) => v.sequence_number === this.sequence_number
            ).is_Added
          ) {
            this.temp_order_line.flex_field =
              this.temp_order_line.flex_field.filter(
                (x) => x.sequence_number !== this.sequence_number
              );
          }
          this.lineFlexDisplayList = this.lineFlexDisplayList?.filter(
            (x) => x.sequence_number !== this.sequence_number
          );
          this.deleteDialog = false;
        } else {
          this.purchaseOrderJson.flex_field.find(
            (v) => v.sequence_number === this.sequence_number
          ).is_Deleted = true;
          if (
            this.purchaseOrderJson.flex_field.find(
              (v) => v.sequence_number === this.sequence_number
            ).is_Deleted &&
            this.purchaseOrderJson.flex_field.find(
              (v) => v.sequence_number === this.sequence_number
            ).is_Added
          ) {
            this.purchaseOrderJson.flex_field =
              this.purchaseOrderJson.flex_field?.filter(
                (x) => x.sequence_number !== this.sequence_number
              );
          }
          this.flexDisplayList = this.flexDisplayList?.filter(
            (x) => x.sequence_number !== this.sequence_number
          );
          this.deleteDialog = false;
          // Update flex
        }
        this.showUpdateLine = false;
        this.showFlexDialog = false;
      } else if (item == "deleteLineItem") {
        this.deleteLineItem();
      }
      this.deleteDialog = false;
    },
    updateFlex(flex_field_Att, flex_field_value) {
      if (this.showLineItem) {
        this.temp_order_line.flex_field = this.temp_order_line.flex_field.map(
          (p) =>
            p.sequence_number == this.sequence_number
              ? { ...p, flex_code: flex_field_Att, flex_data: flex_field_value }
              : p
        );
        this.lineFlexDisplayList = this.lineFlexDisplayList.map((p) =>
          p.sequence_number == this.sequence_number
            ? { ...p, flex_code: flex_field_Att, flex_data: flex_field_value }
            : p
        );
      } else {
        this.purchaseOrderJson.flex_field =
          this.purchaseOrderJson.flex_field.map((p) =>
            p.sequence_number == this.sequence_number
              ? { ...p, flex_code: flex_field_Att, flex_data: flex_field_value }
              : p
          );
        this.flexDisplayList = this.flexDisplayList.map((p) =>
          p.sequence_number == this.sequence_number
            ? { ...p, flex_code: flex_field_Att, flex_data: flex_field_value }
            : p
        );
      }
      this.showFlexDialog = false;
      this.showUpdateLine = false;
    },
    flexDetails(item) {
      this.showFlexDialog = true;
      this.sequence_number = item.sequence_number;
      this.flex_field_Att = item.flex_code;
      this.flex_field_value = item.flex_data;
      this.showLineDelete = true;
      this.showUpdateLine = true;
    },
    // GEt VEndor Data
    async getVenData() {
      if (this.venSearchKey) {
        this.venSearchKey = this.venSearchKey?.toUpperCase();
        if (this.venSearchKey.length > 2) {
          let obj = {
            UserId: this.userId,
            proj_key: this.purchaseOrderJson.proj_id,
            SearchStr: this.venSearchKey,
            pass: 1, // DO not Delte
            strActive: 1,
          };
          let resp = await commonPoService.getVendorData(
            "post",
            obj,
            false,
            true
          );
          if (resp?.vendorInfo) {
            this.vendorList = resp.vendorInfo.map((x) => ({
              title: x.title,
              last: x.first + " " + x.last,
              vendor_id: x.vendor_id,
              vendno: x.vendno,
              company: x.company,
              address1: x.address1,
              address2: x.address2,
              city: x.city,
              state: x.state,
              zip: x.zip,
              country: x.country,
              phone1: x.phone1,
              phone2: x.phone2,
              cell: x.cell,
              email: x.email,
              fax: x.fax,
            }));
          }
        } else {
          this.clearVenAddressData();
        }
      }
    },
    // Clear Vendor Data
    clearVenAddressData() {
      this.vendorList = [];
      this.showVenAddress = false;
      this.purchaseOrderJson.vendorInfo = [
        {
          title: "",
          last: "",
          vendor: "",
          vendor_id: "",
          company: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          phone1: "",
          phone2: "",
          cell: "",
          email: "",
          fax: "",
        },
      ];
    },
    // SHow Vendor
    showVendor(item) {
      Object.assign(this.purchaseOrderJson.vendorInfo[0], item);
      this.venSearchKey = item.company;
      this.vendorList = [];
      this.showVenAddress = true;
    },
    //get Remit Data
    async getRrmitData() {
      if (this.remitSearchKey) {
        this.remitSearchKey = this.remitSearchKey?.toUpperCase();
        if (this.remitSearchKey.length > 2) {
          let obj = {
            UserId: this.userId,
            proj_key: this.purchaseOrderJson.proj_id,
            SearchStr: this.remitSearchKey,
            pass: 2, // Do not Delete
            strActive: 1,
          };
          this.showRemitDDL = true;
          let resp = await commonPoService.getVendorData(
            "post",
            obj,
            false,
            true
          );
          if (resp?.vendorInfo) {
            this.remitList = resp.vendorInfo.map((x) => ({
              title: x.title,
              last: x.first + " " + x.last,
              vendor_id: x.vendor_id,
              vendno: x.vendno,
              company: x.company,
              address1: x.address1,
              address2: x.address2,
              city: x.city,
              state: x.state,
              zip: x.zip,
              country: x.country,
              phone1: x.phone1,
              phone2: x.phone2,
              cell: x.cell,
              email: x.email,
              fax: x.fax,
            }));
          }
        } else {
          this.clearRemitData();
        }
      }
    },
    //clear remit address
    clearRemitData() {
      this.remitList = [];
      this.showVenAddress = false;
      this.sameAsVendorCheck = false;
      this.showRemitAddress = false;
      this.purchaseOrderJson.remitInfo = [
        {
          title: "",
          last: "",
          vendor: "",
          vendor_id: "",
          company: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          phone1: "",
          phone2: "",
          cell: "",
          email: "",
          fax: "",
        },
      ];
    },
    // to show Address
    showRemit(item) {
      if (item) {
        this.showRemitDDL = false;
        this.showRemitAddress = true;
      }
      Object.assign(this.purchaseOrderJson?.remitInfo[0], item);
      this.remitSearchKey = item.company;
      this.remitList = [];
    },
    async addBookmark() {
      let resp = await commonPoService.addBookMark(
        "get",
        this.userId,
        this.purchaseOrderId,
        true
      );
      if (resp?.Result) {
        this.bookmark = true;
        this.showActionsList = this.showActionsList.filter(
          (item) => item.Id != "PO-BookMark"
        );
        this.showActionsList.unshift({
          Id: "PO-BookMark-Remove",
          Icon: "mdi mdi-bookmark-outline",
          FriendlyName: "Remove Bookmark",
        });
      } else this.bookmark = false;
      bus.$emit("bookmark", this.bookmark);
    },
    //remove BookMark
    async removeBookmark() {
      let resp = await commonPoService.getDeleteBookmarkPO(
        "get",
        this.userId,
        this.purchaseOrderId,
        true
      );
      if (resp?.Result) {
        this.bookmark = false;
        this.showActionsList = this.showActionsList.filter(
          (item) => item.Id != "PO-BookMark-Remove"
        );
        this.showActionsList.unshift({
          Id: "PO-BookMark",
          Icon: "mdi mdi-bookmark",
          FriendlyName: "Add Bookmark",
        });
      } else this.bookmark = true;
      bus.$emit("bookmark", this.bookmark);
    },
    // Holde
    warningFunction(text) {
      this.showWarningDialog = true;
      if (text == "hold") {
        this.warningText = "Are you sure you want to put this order on hold?";
        this.holdBtn = true;
        this.releaseBtn = false;
      } else if (text == "release") {
        this.holdBtn = false;
        this.releaseBtn = true;
        this.warningText = "Are you sure you want to release this order?";
      }
    },
    async OnClickOfWarningDialog(text) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let deleteObj = {};

      this.disableHoldBtn = true;
      this.showWarningDialog = false;
      if (text == "hold") {
        deleteObj = {
          po_id: this.purchaseOrderId,
          current_status: "H",
          cancel_reason: "",
          userId: this.userId,
        };
        this.hold = true;
        this.showActionsList = this.showActionsList.filter(
          (item) => item.Id != "PO-Hold"
        );
        this.showActionsList.unshift({
          Id: "PO-Hold",
          Icon: "mdi mdi-play-outline",
          FriendlyName: "PO Release",
        });
        let resp = await commonPoService.postAction("post", deleteObj, true);
        this.purchaseOrderDetails =
          await commonPoService.getPurchaseOrderDetails(
            "get",
            this.userId,
            this.purchaseOrderId
          );
        // Assigning the values we get from API to the Form JSON we created
        this.purchaseOrderJson = JSON.parse(
          JSON.stringify(this.purchaseOrderDetails.Result[0])
        );
        if (resp?.Result) {
          this.purchaseOrderJson.message = resp.Result[0].displaymessage;
          this.holdMsg = false;
          this.disableHoldBtn = false;
        }
        LoaderDialog.visible = false;
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      } else if (text == "release") {
        deleteObj = {
          po_id: this.purchaseOrderId,
          current_status: "",
          cancel_reason: "",
          userId: this.userId,
        };
        this.hold = false;
        let resp = await commonPoService.postAction("post", deleteObj, true);
        if (resp?.Result) {
          this.purchaseOrderJson.message = resp.Result[0].displaymessage;
          this.holdMsg = false;
          this.disableHoldBtn = false;
          this.showActionsList = this.showActionsList.filter(
            (item) => item.Id != "PO-Release"
          );
          this.showActionsList.unshift({
            Id: "PO-Hold",
            Icon: "mdi mdi-pause-box-outline",
            FriendlyName: "PO Hold",
          });
          this.purchaseOrderDetails =
            await commonPoService.getPurchaseOrderDetails(
              "get",
              this.userId,
              this.purchaseOrderId
            );
          // Assigning the values we get from API to the Form JSON we created
          this.purchaseOrderJson = JSON.parse(
            JSON.stringify(this.purchaseOrderDetails.Result[0])
          );
        }
        LoaderDialog.visible = false;
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      } else if (text == "deleteOrder") {
        this.cancelReason = "All line items were removed";
        this.deletePOClicked();
        LoaderDialog.visible = false;
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      }
    },
    // Ext Cost Change
    extCost() {
      this.temp_order_line.receive_ext_cost =
        this.temp_order_line.receive_unit_cost *
        this.temp_order_line.receive_quantity;
      this.temp_order_line.receive_ext_cost =
        this.temp_order_line.receive_ext_cost.toFixed(2);
    },
    //Delete API Call
    async deletePOClicked() {
      let deleteObj = {
        po_id: this.purchaseOrderId,
        current_status: "X",
        cancel_reason: this.cancelReason,
        userId: this.userId,
      };
      let delRes = await commonPoService.postAction("post", deleteObj, true);
      if (delRes?.Result) {
        this.deleteReasonDialog = false;
        this.final_submit = true;
        this.isEditing = false;
        this.deleteBuildDialog = false;
        this.$router.push(`/purchase-order`);
      }
    },
    // Receive Part Number
    async receivePartNumber() {
      this.clearPartReceive()
      if (this.partReturnSearchKey) {
        this.partReturnSearchKey = this.partReturnSearchKey?.toUpperCase();
        if (this.partReturnSearchKey.length > 2) {
          this.showPartRetDetails = true;
          let returnSerachObj = {
            UserId: this.userId,
            SearchStr: this.partReturnSearchKey,
            order_bu_key: this.purchaseOrderJson.bu_id,
          };
          let returnSearchData = await commonPoService.postReturnSearch(
            "post",
            returnSerachObj,
            false,
            true
          );
          if (returnSearchData != "")
            this.returnResultList = returnSearchData?.Resultset;
        }
      }
    },
    // Return Part Desc
    async showReturnPartDesc(item) {
      this.showPartData = true;
      this.returnResultList = [];
      this.partReturnSearchKey = item.part_num;
      this.temp_order_line.receive_part_id = item.part_id;
      this.temp_order_line.receive_part_number = item.part_num;
      this.temp_order_line.receive_part_description = item.part_desc;
      if (this.showPartShip) {
        this.temp_order_line.sh_part_id = this.temp_order_line.receive_part_id;
        this.temp_order_line.sh_part_number =
          this.temp_order_line.receive_part_number;
        this.temp_order_line.sh_part_description =
          this.temp_order_line.receive_part_description;
        this.partShipSearchKey = this.partReturnSearchKey;
        this.showPartDetails = true;
      }

      let warehouseObj = {
        part_num: this.temp_order_line.receive_part_number,
        potype_key: this.temp_order_line.order_line_type,
        order_bu_key: this.purchaseOrderJson.bu_id,
        order_type: this.purchaseOrderJson.order_type_id,
        po_key: this.purchaseOrderId,
        UserId: this.userId,
        loca_key: 0,
        line_id: this.temp_order_line?.line_Id
          ? this.temp_order_line?.line_Id
          : 0,
      };

      this.getWarehouseData(warehouseObj, "receive");
    },
    clearPartReceive() {
      this.partShipSearchKey = "";
      this.$refs.PartRecieveForm.resetValidation();
      this.temp_order_line.receive_part_number = "";
      this.temp_order_line.receive_unit_cost = "0.00";
      this.temp_order_line.receive_part_description = "";
      this.temp_order_line.receive_part_number = "";
      this.temp_order_line.receive_grade = "";
      this.temp_order_line.receive_quantity = "";
      this.temp_order_line.receive_ext_cost = "0.00";
      this.receive_delivery_date = "";
      this.temp_order_line.receive_part_description = "";
      this.temp_order_line.receive_in_inventory_bucket = "";
      this.temp_order_line.receive_in_warehouse = "";
      this.temp_order_line.receive_in_warehouse_id = "";
      this.returnWareList = [];
      this.expected_serial_number = [];
      this.showPartData = false;
      this.extCost();
      this.clearPartShip()
    },
    // Get oRder Type List
    async getOrderTypeData(object) {
      let orderTypeData = await commonPoService.getOrderTypeList(
        "post",
        object
      );
      if (orderTypeData?.OrderTypes) {
        this.notRequired = true;
        this.orderTypeList = orderTypeData.OrderTypes.map((x) => ({
          ord_code: x.ord_code,
          ordCodedesc: x.ordCodedesc,
        }));
      } else {
        this.notRequired = false;
        this.disableAuto = false;
      }
      if (orderTypeData?.PaymentTerms) {
        this.paymentList = orderTypeData.PaymentTerms.map((x) => ({
          payterm_id: x.list_id,
          payterm_desc: x.displaystring,
        }));
      }
    },
    //common Function for Warehouse
    async getWarehouseData(shipObject, text) {
      let shipWarehouseList = await commonPoService.postWarehouseData(
        "post",
        shipObject
      );
      this.showexpIcon = shipWarehouseList?.SnInfo[0]?.enable_expected_sn;
      if (text != "viewLine") {
        this.temp_order_line.receive_unit_cost =
          shipWarehouseList?.CostInfo[0]?.defaultcost.toFixed(2);
      }
      this.extCost();
      if (shipWarehouseList?.ShipWare) {
        this.shipWareList = shipWarehouseList?.ShipWare.map((x) => ({
          ware: x.ware,
          ware_id: x.ware_id + "_" + x.bucket,
          bucket: x.bucket,
          from_warebuck: x.from_warebuck,
          mess_bit: x.mess_bit,
          aqty: x.aqty,
          show_text: x.show_text,
          cg_desc: x.cg_desc,
          rm_id: x.rm_id,
        }));
        let cosmeticData = this.shipWareList?.filter(
          (x) => x.ware_id == this.temp_order_line.sh_from_warehouse_id
        );
        this.shipCosmeticList = cosmeticData.map((x) => ({
          cg_desc: x.cg_desc,
          rm_id: x.rm_id,
        }));
      }
      if (shipWarehouseList?.ReceiveWare != "") {
        this.returnWareList = shipWarehouseList?.ReceiveWare?.map((x) => ({
          ware: x.ware,
          ware_id: x.ware_id + "_" + x.bucket,
          bucket: x.bucket,
          into_warebuck: x.into_warebuck,
          cg_desc: x.cg_desc,
          rm_id: x.rm_id,
        }));
        let cosmeticData = this.returnWareList?.filter(
          (x) => x.ware_id == this.temp_order_line.receive_in_warehouse_id
        );
        this.receiveCosmeticList = cosmeticData.map((x) => ({
          cg_desc: x.cg_desc,
          rm_id: x.rm_id,
        }));
        if (this.temp_order_line.receive_grade == 0) {
          let calReceiveGrade = this.receiveCosmeticList.filter(
            (x) => x.cg_desc == "No Grade"
          );
          if (calReceiveGrade.length > 0) {
            this.temp_order_line.receive_grade = calReceiveGrade[0].rm_id;
          }
        }
      }
    },
    //commone Function for Line item Get Datass
    async getLineTypeData(obj) {
      let LineTypeData = await commonPoService.postOrderTypeData("post", obj);
      if (LineTypeData?.LineTypeInfo) {
        this.LineTypeList = LineTypeData.LineTypeInfo.map((x) => ({
          so_type_id: x.sotype_id,
          so_desc: x.so_desc,
          so_type: x.so_type,
          to_ship: x.to_ship,
          to_return: x.to_return,
          to_disable_quantity: x.to_disable_quantity,
          to_disable_ReturnSel: x.to_disable_ReturnSel,
        }));
        this.validateLineType();
      }
    },
    //Action List
    setActionFunction() {
      if (this.actionSetter("DeletePurchaseOrder"))
        this.showActionsList.push({
          Id: "PO-Delete",
          Icon: "mdi-delete",
          FriendlyName: "PO Delete",
        });
      this.showActionsList.push({
        Id: "PO-Reset",
        Icon: "mdi-refresh",
        FriendlyName: "Reset",
      });
    },
    // Action based
    actionSetter(action) {
      if (
        this.actionList !== undefined &&
        this.actionList !== null &&
        this.actionList !== ""
      ) {
        let filter = this.actionList.filter(
          (entry) => entry.SystemName === action
        );
        return filter !== undefined && filter !== null && filter.length == 1
          ? true
          : false;
      } else return false;
    },
    //Yes button on the refresh popup click event
    refreshYesClick() {
      this.refreshDialog = false;
    },
    //No button on the refresh popup click event
    refreshNoClick() {
      this.refreshDialog = false;
    },
    // Parent Nav function
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
    },
    // Change Part Number
    onPartReturnChange(warehouse_id) {
      this.temp_order_line.receive_grade = ""
      let returnShipObj = this.returnWareList.filter(
        (x) => x.ware_id == warehouse_id
      );
      this.temp_order_line.receive_in_inventory_bucket =
        returnShipObj[0].bucket;
      this.temp_order_line.receive_in_warehouse = returnShipObj[0].ware;
      this.temp_order_line.receive_in_warehouse_id = returnShipObj[0].ware_id;
      let cosmeticData = this.returnWareList.filter(
        (x) => x.ware_id == warehouse_id
      );
      this.receiveCosmeticList = cosmeticData.map((x) => ({
        cg_desc: x.cg_desc,
        rm_id: x.rm_id,
      }));
    },
    // On Add Flex
    onClickFlexAdd(flex_field_Att, flex_value) {
      if (this.showLineItem) {
        let flx_ord_seq =
          this.temp_order_line.flex_field.length == undefined ||
            this.temp_order_line.flex_field.length == null
            ? 0
            : this.temp_order_line.flex_field.length;
        let order_line_num =
          this.purchaseOrderJson.order_line.length == null ||
            this.purchaseOrderJson.order_line.length == undefined
            ? 0
            : this.purchaseOrderJson.order_line.length;
        let obj = {
          temp_flex_id: flx_ord_seq + 1,
          sequence_number: order_line_num + 1,
          flex_code: flex_field_Att,
          flex_data: flex_value,
          lineflex_id: "",
          is_Deleted: false,
          is_Added: true,
        };
        this.temp_order_line.flex_field.push(obj);
        this.lineFlexDisplayList.push(obj);
      } else {
        let flxseq =
          this.purchaseOrderJson.flex_field.length == undefined ||
            this.purchaseOrderJson.flex_field.length == null
            ? 0
            : this.purchaseOrderJson.flex_field.length;
        let obj = {
          sequence_number: flxseq + 1,
          flex_code: flex_field_Att,
          flex_data: flex_value,
          soflex_id: 0,
          is_Added: true,
          is_Deleted: false,
        };
        this.purchaseOrderJson.flex_field.push(obj);
        this.flexDisplayList.push(obj);
      }

      this.showFlexDialog = false;
      this.flex_field_Att = "";
      this.flex_field_value = "";
      this.$refs.flexForm.resetValidation();
    },
    clearFlexItem() {
      this.showFlexDialog = false;
      this.flex_field_Att = "";
      this.flex_value = "";
      this.$refs.flexForm.resetValidation();
    },
    //onClick Delete
    onClickLineDelete(item) {
      if (item == "Flex") {
        this.deleteFlexItem = true;
        this.deleteDialog = true;
      } else if (item == "Line" && this.totalRecords > 1) {
        this.deleteLine = true;
        this.deleteDialog = true;
      }
      if (this.totalRecords == 1) {
        this.showWarningDialog = true;
        this.deleteLine = false;
        this.holdBtn = false;
        this.releaseBtn = false;
        this.warningText = "Are you sure you want to delete this Line Item?";
        this.deleteOrder = true;
      }
    },
    onChangeShipping() {
      this.purchaseOrderJson.saturday_delivery = false;
      this.purchaseOrderJson.signature_required = false;
      let CarrierFlags = this.returnCarrierList.filter(
        (x) => x.sl_id == this.purchaseOrderJson.inbound_carrier
      );
      this.disableSignature = CarrierFlags[0].sig_required;
      this.disableSatDel = CarrierFlags[0].sat_delivery;
    },
    async postPoUpdate() {
      if (this.$refs.salesOrderForm.validate()) {
        if (
          this.$refs.CarrierLevlInfoForm.validate() &&
          this.$refs.addressForm.validate()
        ) {
          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          this.final_submit = true;
          this.purchaseOrderJson.ordered_date =
            this.purchaseOrderJson.ordered_date = this.purchaseOrderJson.ordered_date ?
              Utility.convertLocalToUTC(this.purchaseOrderJson.ordered_date) : "";
          this.purchaseOrderJson.delivery_date = this.purchaseOrderJson.delivery_date ?
            Utility.convertLocalToUTC(this.purchaseOrderJson.delivery_date) : ""
          let final_save = {
            UserId: this.userId,
            json: JSON.stringify(this.purchaseOrderJson),
          };
          let saveOrder = await commonPoService.postPoUpdate(
            "post",
            final_save,
            true
          );
          if (saveOrder?.Result[0]?.Message) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            if (this.totalRecords == 0) {
              this.$router.push(`/purchase-order`);
            } else {
              this.$router.push(
                `/purchase-order-details/${btoa(
                  this.purchaseOrderId.toString()
                )}`
              );
            }
          }
        } else {
          if (
            !this.$refs.addressForm.validate() &&
            !this.$refs.CarrierLevlInfoForm.validate()
          ) {
            let invalidField = this.$refs.addressForm.$children.find(
              (e) => !e.valid
            );
            if (invalidField)
              invalidField.$el.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            if (this.returnReq) this.disableReturn = false;
            else this.disableShip2 = false;

            this.disableShippingSection = false;
          } else if (!this.$refs.addressForm.validate()) {
            let invalidField = this.$refs.addressForm.$children.find(
              (e) => !e.valid
            );
            if (invalidField)
              invalidField.$el.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            if (this.returnReq) this.disableReturn = false;
            else this.disableShip2 = false;
          } else {
            this.disableShippingSection = false;
            let invalidField = this.$refs.CarrierLevlInfoForm.$children.find(
              (e) => !e.valid
            );
            if (invalidField)
              invalidField.$el.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
          }
        }
      } else {
        let invalidField = this.$refs.salesOrderForm.$children.find(
          (e) => !e.valid
        );
        if (invalidField)
          invalidField.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
      }
    },
    // To open Dialog box of resptective address
    addressDialog(item) {
      (this.showAddressDialog = true), (this.addressDialogTitle = item);
    },
    // Clear Part Item
    clearLineItem() {
      this.disableBuildSave = true;
      this.showPartShipDetails = false;
      this.buildPartNumList = false;
      this.showPartRetDetails = false;
      this.showPartDetails = false;
      this.disableQty = false;
      this.showBuildExpand = false;
      this.showStockStatus = false;
      this.disablePartRecieve = false;
      this.showPartData = false;
      this.serial_num = "";
      this.asset_tag = "";
      this.problem_desc = "";
      this.warr_status = "N";
      this.tracking_num = "";
      this.receive_carrier = "";
      this.stockMessage = "";
      this.temp_order_line = {
        po_line_ref: "",
        sequence_number: "",
        order_line_type: "",
        line_number: "",
        sh_part_id: "",
        sh_part_number: "",
        sh_part_description: "",
        sh_from_warehouse_id: "",
        sh_from_warehouse: "",
        sh_from_inventory_bucket: "",
        sh_quantity: "",
        sh_ship_by_date: "",
        sh_grade: "",
        receive_part_id: "",
        receive_part_number: "",
        receive_part_description: "",
        receive_in_warehouse_id: "",
        receive_in_warehouse: "",
        receive_in_inventory_bucket: "",
        receive_quantity: "",
        receive_unit_cost: "0.00",
        receive_rma: "",
        receive_grade: "",
        receive_delivery_date: "",
        receive_ext_cost: "",
        expected_serial_number: [],
        line_comment: "",
        vendor_part_number: "",
        flex_field: [],
        // Add by Dev team for showing text
        order_line_text: "",
        showPartRet: false,
        showPartShip: false,
        showBuild: "",
        showSerial: "",
      };
      this.showLineDelete = false;
      this.showExpectedSr = false;
      this.sameAsShip = false;
      (this.shipPartName = ""), (this.partBuildPartNum = "");
      this.buildToQty = "";
      this.shipPartDesc = "";
      this.partShipSearchKey = "";
      this.partReturnSearchKey = "";
      this.serial_num = "";
      this.warranty_status = "";
      this.asset_tag = "";
      this.problem_desc = "";
      this.disableLine = false;
      this.showPartShip = false;
      this.showFlex = false;
      this.showPartRet = false;
      this.showexpIcon = false;
      this.showBuildIcon = false;
      this.showBuildExpand = false;

    },
    // To clean part number once its click on cancel
    clearPartShip() {
      this.$refs?.PartShipForm?.resetValidation()
      this.shipPartName = "";
      this.temp_order_line.sh_ship_by_date = ""
      this.shipPartDesc = "";
      this.temp_order_line.sh_price = "";
      this.temp_order_line.sh_quantity = "";
      this.temp_order_line.sh_from_warehouse_id = "";
      this.showPartDetails = false;
      this.temp_order_line.sh_part_number = "";
      this.temp_order_line.sh_part_description = "";
      this.temp_order_line.sh_grade = "";
      this.disableQty = false;
      this.shipWareList = [];
      this.temp_order_line.sh_from_warehouse = "";
      this.temp_order_line.sh_from_inventory_bucket = "";
      this.temp_order_line.sh_part_id = "";
    },
    // Validation based of Line Type CHeck
    validateLineType() {
      //User to find Unique items in an array;
      let lineItemUsedObj = this.purchaseOrderJson.order_list.reduce(
        (acc, cur) => {
          acc[cur.order_line_type] = true;
          return acc;
        },
        {}
      );
      let lineTypeListObj = this.LineTypeList.reduce((acc, cur) => {
        acc[cur.so_type_id] = cur;
        return acc;
      }, {});
      Object.keys(lineTypeListObj).forEach((key) => {
        if (lineItemUsedObj[key]) {
          this.shippingReq =
            this.shippingReq || Boolean(lineTypeListObj[key].to_ship);
          this.returnReq = this.returnReq || !!lineTypeListObj[key].to_return;
        }
      });
    },
    // To get warrenty Data
    async warrentyData() {
      let warrentyListData = await commonPoService.getWarrentyData(
        "get",
        this.userId
      );
      if (warrentyListData != "") {
        this.warrentyList = warrentyListData.WarrantyData;
      }
    },
    // On click of Add List
    async onClickAddList() {
      let object = {
        ProjKey: this.purchaseOrderJson.proj_id,
        UserId: this.userId,
        POId: this.purchaseOrderId,
        line_id: 0,
      };
      this.disableLine = false;
      await this.getLineTypeData(object);
      this.disableQty = true;
      this.enableEdit = false;
      this.showLineItem = true;
      this.clearLineItem();
      this.warrentyData();
    },
    //Get Part Number Ship Data
    //Get Ship  Data
    async partShipData() {
      this.clearPartShip()
      if (this.partShipSearchKey) {
        this.partShipSearchKey = this.partShipSearchKey?.toUpperCase();

        if (this.partShipSearchKey.length > 2) {
          this.showPartShipDetails = true;
          let shipDataObj = {
            UserId: this.userId,
            SearchStr: this.partShipSearchKey,
            order_bu_key: this.purchaseOrderJson.bu_id,
            potype_key: this.temp_order_line.order_line_type,
            line_key: 0,
            left_part_key: this.temp_order_line.receive_part_id
              ? this.temp_order_line.receive_part_id
              : 0,
          };
          let shipDataSearch = await commonPoService.postShipSearch(
            "post",
            shipDataObj,
            false,
            true
          );
          if (shipDataSearch?.Resultset)
            this.partResultList = shipDataSearch.Resultset;
        }
      }
    },
    //Show Part Number Details
    async showPartDesc(item) {
      this.showPartDetails = true;
      this.partResultList = [];
      this.temp_order_line.sh_part_id = item.part_id;
      this.shipPartName = item.part_num;
      this.partShipSearchKey = item.part_num;
      this.shipPartDesc = item.part_desc;
      this.shipPartKey = item.part_id;
      let shipWareObj = {
        part_num: item.part_num,
        order_type: this.purchaseOrderJson.order_type,
        potype_key: this.temp_order_line.order_line_type,
        order_bu_key: this.purchaseOrderJson.bu_id,
        po_key: this.purchaseOrderId,
        UserId: this.userId,
        loca_key: 0,
        line_id: this.temp_order_line?.line_Id
          ? this.temp_order_line?.line_Id
          : 0,
      };
      this.vendorDisable = false;
      this.getWarehouseData(shipWareObj, "ship");
    },

    // Same as Part to Recive
    onChangeSameAsShip() {
      if (this.sameAsShip) {
        this.partReturnSearchKey = this.partShipSearchKey;
        this.returnPartNum = this.shipPartName;
        this.returnPartDesc = this.shipPartDesc;
        this.temp_order_line.receive_part_number =
          this.temp_order_line.sh_part_number;
        this.temp_order_line.receive_quantity =
          this.temp_order_line.sh_quantity;
        this.temp_order_line.return_price = this.temp_order_line.sh_price;
        this.temp_order_line.receive_part_description =
          this.temp_order_line.sh_part_description;
        this.temp_order_line.return_part_id = this.temp_order_line.sh_part_id;
      } else {
        this.temp_order_line.receive_part_number = "";
        //this.temp_order_line.receive_quantity = ""
        this.temp_order_line.return_price = "";
        this.temp_order_line.receive_part_description = "";
        this.temp_order_line.receive_in_warehouse_id = "";
        this.temp_order_line.return_part_id = "";
        this.partReturnSearchKey = "";
        this.returnPartNum = "";
        this.returnPartDesc = "";
      }
    },
    // return to ship
    async returnPartData() {
      this.temp_order_line.receive_quantity = this.temp_order_line.sh_quantity;
      if (this.partReturnSearchKey) {
        this.partReturnSearchKey = this.partReturnSearchKey?.toUpperCase();
        if (this.partReturnSearchKey.length > 2) {
          this.showPartRetDetails = true;
          let returnSerachObj = {
            UserId: this.userId,
            SearchStr: this.partReturnSearchKey,
            order_bu_key: this.purchaseOrderJson.bu_id,
            order_type: this.purchaseOrderJson.order_type,
            sotype_key: this.temp_order_line.order_line_type,
            ship_part_key: this.shipPartKey,
            line_key: 0, // Do not Delete
          };
          let returnSearchData = await commonPoService.postReturnSearch(
            "post",
            returnSerachObj,
            false,
            true
          );
          if (returnSearchData != "")
            this.returnResultList = returnSearchData.Resultset;
        }
      }
    },
    //onClick Delete
    //Delete Line Item
    deleteLineItem() {
      this.temp_order_line.receive_in_warehouse_id =
        this.temp_order_line?.receive_in_warehouse_id?.split("_")[0];
      this.temp_order_line.sh_from_warehouse_id =
        this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
      if (this.temp_order_line.is_Added) {
        this.purchaseOrderJson.order_line =
          this.purchaseOrderJson?.order_line.filter(
            (x) => x.line_number !== this.edit_line_num
          );
      } else if (
        !this.temp_order_line.is_Added &&
        !this.temp_order_line.is_Deleted
      ) {
        this.purchaseOrderJson.order_line =
          this.purchaseOrderJson?.order_line.filter(
            (x) => x.line_number !== this.edit_line_num
          );
        this.temp_order_line.is_Deleted = true;
        this.temp_order_line.is_Edited = true;
        this.purchaseOrderJson.order_line.push(this.temp_order_line);
      } else {
        this.temp_order_line.is_Deleted = true;
        this.temp_order_line.is_Edited = true;
        this.purchaseOrderJson.order_line.push(this.temp_order_line);
      }
      this.purchaseOrderJson.order_list =
        this.purchaseOrderJson?.order_list.filter(
          (x) => x.line_number !== this.edit_line_num
        );
      this.deleteBuildDialog = false;
      this.showLineItem = false;
      this.totalRecords = this.purchaseOrderJson.order_list.length;
    },
    //Cancel Line Item
    cancelLineItems() {
      this.clearLineItem();
      this.showLineItem = false;
      this.editLinePart = true;
      this.enableEdit = true;
    },
    // On Click Add Part List Item
    submitLineItems() {
      this.temp_order_line.receive_delivery_date = this.temp_order_line.receive_delivery_date ?
        Utility.convertLocalToUTC(this.temp_order_line.receive_delivery_date) : ""
      this.temp_order_line.sh_ship_by_date = this.temp_order_line.sh_ship_by_date ?
        Utility.convertLocalToUTC(this.temp_order_line.sh_ship_by_date) : ""
      this.temp_order_line.Allocated = 0;
      this.temp_order_line.Picked = 0;
      this.temp_order_line.Shipped = 0;
      this.temp_order_line.Rcvd = 0;
      this.temp_order_line.enable_save = true;
      let order_line_num =
        this.purchaseOrderJson.order_list.length == null ||
          this.purchaseOrderJson.order_list.length == undefined
          ? 0
          : this.purchaseOrderJson.order_list.length;
      this.temp_order_line.is_FAllocated = false;
      if (this.showPartShip && this.showPartRet) {
        if (
          this.$refs.PartShipForm.validate() &&
          this.$refs.PartRecieveForm.validate()
        ) {
          this.temp_order_line.receive_in_warehouse_id =
            this.temp_order_line?.receive_in_warehouse_id.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id =
            this.temp_order_line?.sh_from_warehouse_id.split("_")[0];
          this.temp_order_line.line_number = order_line_num + 1;
          this.temp_order_line.sequence_number =
            this.temp_order_line.line_number;
          this.temp_order_line.is_Added = true;
          this.temp_order_line.is_Edited = false;
          this.temp_order_line.is_Deleted = false;
          this.temp_order_line.quantity = this.temp_order_line.sh_quantity;
          this.temp_order_line.Open_Shipments =
            this.temp_order_line.quantity - this.temp_order_line.Shipped;
          this.temp_order_line.Open_Receipts =
            this.temp_order_line.quantity - this.temp_order_line.Rcvd;
          this.purchaseOrderJson.order_list.push(this.temp_order_line);
          this.purchaseOrderJson.order_line.push(this.temp_order_line);
          this.showLineItem = false;
          this.clearLineItem();
        }
      } else if (this.showPartShip && !this.showPartRet) {
        if (this.$refs.PartShipForm.validate()) {
          this.temp_order_line.receive_in_warehouse_id =
            this.temp_order_line?.receive_in_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id =
            this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          this.temp_order_line.line_number = order_line_num + 1;
          this.temp_order_line.sequence_number =
            this.temp_order_line.line_number;
          this.temp_order_line.is_Added = true;
          this.temp_order_line.is_Edited = false;
          this.temp_order_line.is_Deleted = false;
          this.temp_order_line.quantity = this.temp_order_line.sh_quantity;
          this.temp_order_line.Open_Receipts = 0;
          this.temp_order_line.Open_Shipments =
            this.temp_order_line.quantity - this.temp_order_line.Shipped;
          this.purchaseOrderJson.order_list.push(this.temp_order_line);
          this.purchaseOrderJson.order_line.push(this.temp_order_line);
          this.showLineItem = false;
          this.clearLineItem();
        }
      } else if (!this.showPartShip && this.showPartRet) {
        if (this.$refs.PartRecieveForm.validate()) {
          this.temp_order_line.receive_in_warehouse_id =
            this.temp_order_line?.receive_in_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id =
            this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          this.temp_order_line.line_number = order_line_num + 1;
          this.temp_order_line.sequence_number =
            this.temp_order_line.line_number;
          this.temp_order_line.is_Edited = false;
          this.temp_order_line.is_Deleted = false;
          this.temp_order_line.quantity = this.temp_order_line.receive_quantity;
          this.temp_order_line.Open_Shipments = 0;
          this.temp_order_line.Open_Receipts =
            this.temp_order_line.quantity - this.temp_order_line.Rcvd;
          this.temp_order_line.is_Added = true;
          this.purchaseOrderJson.order_list.push(this.temp_order_line);
          this.purchaseOrderJson.order_line.push(this.temp_order_line);
          this.showLineItem = false;
          this.clearLineItem();
        }
      }
      this.totalRecords = this.purchaseOrderJson.order_list.length;
      this.validateLineType();
    },
    // on Change LIne Typeh
    OnChangeLineType(value) {
      if (value.order_line_type == 1) {
        this.panel = [0];
      }
      else if (value.order_line_type == 8) {
        this.panel = [1];
      }
      else {
        this.panel = [0, 1];
      }

      this.disableLine = true;
      let obj = this.LineTypeList.filter((x) => x.so_type_id == value);
      this.showPartShip = obj[0].to_ship == 1 ? true : false;
      this.showPartRet = obj[0].to_return == 1 ? true : false;
      this.showFlex = true;
      this.temp_order_line.order_line_text = obj[0].so_desc;
      this.temp_order_line.showPartShip = this.showPartShip;
      this.temp_order_line.showPartRet = this.showPartRet;
      this.disableQty = obj[0].to_disable_quantity == 1 ? true : false;
      this.disablePartShip = obj[0].to_disable_ReturnSel == 1 ? true : false;
      // Default Value 1 for CR and EX Line Types
      if (value == 2 || value == 6) {
        this.temp_order_line.sh_quantity = 1;
      }
      this.temp_order_line.receive_rma = this.purchaseOrderJson.rma;
    },
    // On Chnage Part SHip WareHouse DropDOwn
    onPartShipChange(warehouse_id) {
      this.temp_order_line.sh_grade = "";
      let partShipObj = this.shipWareList.filter(
        (x) => x.ware_id == warehouse_id
      );
      this.temp_order_line.sh_from_inventory_bucket = partShipObj[0]?.bucket;
      this.temp_order_line.sh_from_warehouse = partShipObj[0]?.ware;
      this.temp_order_line.sh_from_warehouse_id = partShipObj[0]?.ware_id;
      this.showStockStatus = partShipObj[0].mess_bit;
      this.stockMessage = partShipObj[0].show_text;
      let cosmeticData = this.shipWareList.filter(
        (x) => x.ware_id == warehouse_id
      );
      this.shipCosmeticList = cosmeticData.map((x) => ({
        cg_desc: x.cg_desc,
        rm_id: x.rm_id,
      }));
    },
    //Reset Data to API Call JSON
    resetFunction() {
      this.showOrderedDate = false;
      this.showDeliveryDate = false;
      this.showTempShipByDate = false;
      this.showTempDeliveryDate = false;
      this.$nextTick(() => {
        this.showOrderedDate = true;
        this.showDeliveryDate = true;
        this.showTempShipByDate = true;
        this.showTempDeliveryDate = true;
        this.temp_order_line.receive_delivery_date = null
        this.temp_order_line.sh_ship_by_date = null
        this.purchaseOrderJson.delivery_date = null
        this.purchaseOrderJson.ordered_date = null
      })
      this.purchaseOrderJson = JSON.parse(
        JSON.stringify(this.purchaseOrderDetails.Result[0])
      );
    },
    // Update API
    //Hold SO
    async postSoHold() {
      this.disableHoldBtn = true;
      this.showForceDialog = false;
      let deleteObj = {
        so_id: this.salesOrderId,
        current_status: "H",
        userId: this.userId,
      };
      let resp = await commonPoService.postPODelete("post", deleteObj, true);
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      if (resp?.Result) {
        this.purchaseOrderJson.message = resp.Result[0].displaymessage;
        this.holdMsg = false;
        this.hold = true;
        this.disableHoldBtn = false;
        this.showActionsList = this.showActionsList.filter(
          (item) => item.Id != "SO-Hold"
        );
        this.showActionsList.unshift({
          Id: "SO-Hold",
          Icon: "mdi mdi-play-outline",
          FriendlyName: "SO Release",
        });
        this.purchaseOrderDetails =
          await commonPoService.getPurchaseOrderDetails(
            "get",
            this.userId,
            this.purchaseOrderId
          );
        // Assigning the values we get from API to the Form JSON we created
        this.purchaseOrderJson = JSON.parse(
          JSON.stringify(this.purchaseOrderDetails.Result[0])
        );
        LoaderDialog.visible = false;
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      } else this.hold = false;
      // bus.$emit("hold", this.hold);
      this.releaseMsg = false;
      this.totalRecords = this.purchaseOrderJson.order_list.length;
    },
    //Release Hold
    async postSoHoldRelease() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.disableHoldBtn = true;
      this.showForceDialog = false;
      let deleteObj = {
        so_id: this.salesOrderId,
        current_status: "",
        cancel_reason: "",
        userId: this.userId,
      };
      let resp = await commonPoService.postPODelete("post", deleteObj, true);
      if (resp?.Result) {
        this.hold = false;
        this.releaseMsg = false;
        this.disableHoldBtn = false;
        this.showActionsList = this.showActionsList.filter(
          (item) => item.Id != "SO-Release"
        );
        this.showActionsList.unshift({
          Id: "SO-Hold",
          Icon: "mdi mdi-pause-box-outline",
          FriendlyName: "SO Hold",
        });
        this.purchaseOrderDetails =
          await commonPoService.getPurchaseOrderDetails(
            "get",
            this.userId,
            this.purchaseOrderId
          );
        // Assigning the values we get from API to the Form JSON we created
        this.purchaseOrderJson = JSON.parse(
          JSON.stringify(this.purchaseOrderDetails.Result[0])
        );

        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      } else this.hold = true;
      //  bus.$emit("hold", this.hold);
      this.holdMsg = false;
    },
    // Reset Line Data
    resetLineData() {
      if (this.showLineDelete)
        this.temp_order_line = JSON.parse(
          JSON.stringify(this.tempOrderLineBackup)
        );
      else this.clearLineItem();
    },
    async getCarrierData(carrierObj) {
      let carrierData = await commonPoService.postCarrierData(
        "post",
        carrierObj
      );
      this.returnCarrierList = carrierData.InboundSLs;
      this.shipCarrierList = carrierData.OutboundSLs;
    },
    async orderHistory() {
      let historyData = await commonPoService.getPOActionLog(
        this.purchaseOrderId,
        this.userId
      );
      if (historyData !== null && historyData !== undefined) {
        if (historyData?.ActionLogInfo?.length > 0) {
          const newArr = historyData?.ActionLogInfo?.map((obj) => {
            return {
              ...obj,
              Logged: Utility.convertESTToLocal(obj.Logged),
            };
          });
          this.purchaseOrderHistoryItems = newArr;
          this.purchaseOrderHistoryDialog = true;
        }
        else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "No history available",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
    async onClickedHyperLink(value, item) {

      this.selectedLink = value;
      if (value === "Picked") {
        let pickedData = await commonPoService.getPurchaseOrderPickedData(
          "get",
          this.purchaseOrderId,
          item.line_number,
          this.userId
        );
        this.pickedDataList = pickedData.Data;
        this.pickedDataList.forEach((element) => {
          element.Picked = Utility.convertESTToLocal(element.Picked);
        });
        this.showLinkDialog = true;
      } else if (value === "Received") {
        let receivedData = await commonPoService.getPurchaseOrderReceivedData(
          "get",
          this.purchaseOrderId,
          item.line_number,
          this.userId
        );

        this.receivedDataList = receivedData.Data;
        this.receivedDataList.forEach((element) => {
          element.Date = Utility.convertESTToLocal(element.Date);
        });
        this.showLinkDialog = true;
      } else if (value === "Shipped") {
        let shippedData = await commonPoService.getPurchaseOrderShippedData(
          "get",
          this.purchaseOrderId,
          item.line_number,
          this.userId
        );
        this.shippedDataList = shippedData.Data;
        this.shippedDataList.forEach((element) => {
          element.Shipped = Utility.convertESTToLocal(element.Shipped);
        });
        this.showLinkDialog = true;
      }
    },
  },
  components: {
    breadcrumbComp,
    addressComp,
    BackToTop,
  },
};
